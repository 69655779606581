import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://48b708e6ff042f884a38373a71999dc8@o1201145.ingest.us.sentry.io/4507678115823616",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});